import React, { useEffect, useState } from 'react';
import './navbar.css';
import Bpepelogo from '../assets/bgbpepe.png';

function Navbar() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-left">
        {/* Logo */}
        <a href="/">
          <img src={Bpepelogo} alt="Logo" className="logo" />
        </a>
      </div>
      <div className="navbar-right">
        {/* Menu items */}
        <ul className="menu">
          <li>
            <a href="https://t.me/BitPEPEPortal" target="_blank" rel="noopener noreferrer">TELEGRAM</a>
          </li>
          <li>
            <a href="https://twitter.com/BitPEPE_ETH" target="_blank" rel="noopener noreferrer">TWITTER</a>
          </li>
          <li>
            <a href="/" target="_blank" rel="noopener noreferrer">CONTRACT</a>
          </li>
          <li>
            <a href="/" target="_blank" rel="noopener noreferrer">CHART</a>
          </li>
        </ul>

      </div>
    </nav>
  );
}

export default Navbar;
