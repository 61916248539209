import React from 'react';
import './hero.css';
import videoSource from '../assets/bgvid.mp4';

const Hero = () => {
  return (
    <div className="hero">        
     
      <div className="hero-content">
        <a href="https://t.me/BitPEPEPortal" target="_blank" rel="noopener noreferrer" className="h1-link">
          <h1>BUY</h1>
        </a>
      </div>
      <video className="video-background" autoPlay loop muted>
        <source src={videoSource} type="video/mp4" />
        {/* Add fallback content here */}
      </video>
    </div>
  );
};

export default Hero;
