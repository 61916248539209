import React from 'react';
import Navbar from './components/navbar.js';
import Hero from './components/hero.js';


function App() {
  return (
    <div>
      <Navbar />
      <Hero />
    </div>
  );
}

export default App;